import React from "react"
import { graphql } from "gatsby"
import Meta from "../components/meta"
import Single from "../components/single"

export const Head = ({ data }: any) => {
  const post = data.strapiLaw
  return (
    <Meta title={post.title} description={post.excerpt} image={post.cover} isArticle={true} />
  )
}

const LawSinglePage = ({ data }: any) => {
  const post = data.strapiLaw

  return (
    <Single post={post} type="law" />
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiLaw(slug: { eq: $slug }) {
      ...LawData
      publishedAt(formatString: "DD MMMM YYYY", locale: "th-TH")
      blocks {
        ...RichTextData
      }
    }
  }
`

export default LawSinglePage
